import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { saturate, lighten } from "polished";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text } from "../../components/Core";

import { device } from "../../utils";
// @ts-expect-error false positive image import failure
import imgHeroTab from "../../assets/image/radios/pnc-370.png";
// @ts-expect-error false positive image import failure
import imgL2HeroBg from "../../assets/image/heros/retail.png";

const SectionStyled = styled(Section)`
  background: url(${imgL2HeroBg}) no-repeat;
  background-size: cover;
  background-position: top;
  position: relative;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: .3;
`

const ImgRight = styled(Box)`
  position: relative;
  left: -100px;
  @media ${device.md} {
    position: absolute;
    top: 0;
    max-width: 100%;
    min-width: 100%;
    right: -500px;
    left: auto;
    transform: translateX(25%);
  }
`;

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <SectionStyled bg="#f7f7fb" className="position-relative">
        <Overlay />
        <div className="pt-5"></div>
        <Container>
          <Row className="justify-content-start align-items-center position-relative">
            <Col md="10" className="order-md-1">
              <div
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-delay="500"
                data-aos-once="true"
              >
                <Box py={[null, null, null, 5]} pr={5} pt={[4, null]}>
                  <Title variant="hero" textColor="light">
                    Internal Communication
                  </Title>
                  <Text mb={4} color="light" opacity={0.7}>
                    Treat every customer to personal service, no matter how big your store—with real-time voice communication.
                  </Text>
                  {/* <div className="d-flex flex-column align-items-start pt-3">
                    <Button mb={2}>Download our brochure</Button>
                  </div> */}
                </Box>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Hero;
