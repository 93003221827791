import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";

// @ts-expect-error false positive image import failure
import imgHalf from "../../assets/image/radios/retail.jpeg";

const ImageHalfContainer = styled(Box)`
  position: static;
  right: -260px;
  z-index: 0;
  height: 100%;
  overflow: hidden;

  .img-half {
    height: auto;
    @media ${device.lg} {
      height: 100%;
    }
  }
  @media ${device.lg} {
    position: absolute !important;
    height: 100%;
    right: -250px;
  }
  @media ${device.xl} {
    position: absolute !important;
    height: 100%;
    right: -500px;
  }
`;

const Content1 = () => (
  <>
    <div className="overflow-hidden" style={{ backgroundColor: "#f7f7fb" }}>
      <Container>
        <Row>
          <Col
            md={8}
            lg={5}
          >
            <Section py={[5, null, null, "50px"]}>
              <div className="omga-02__content-text section-title order-lg-1">
                <Title>
                  Internal DMR
                </Title>
                <Text fontSize="24px" fontWeight="700" color="primary">Reliable connectivity</Text>
                <Text variant="small" mb={3}>
                  With no infrastructure required internal DMR has not single point of failure and will keep running even if you lose power.
                </Text>
                <Text fontSize="24px" fontWeight="700" color="primary">Wide area coverage</Text>
                <Text variant="small" mb={3}>
                 Private radio networks can provide coverage and capacity exactly where it is needed, eliminating not-spots to ensure reliable coverage in all areas.
                </Text>
                <Text fontSize="24px" fontWeight="700" color="primary">Emergency button</Text>
                <Text variant="small" mb={3}>
                Radios come with an emergency ‘panic’ button, which can be programmed to send an alarm to the command centre and to other radios, along with the person’s ID.
                </Text>
                <Text fontSize="24px" fontWeight="700" color="primary">Man Down and Lone Worker</Text>
                <Text variant="small" mb={3}>
                The Man Down alarm is triggered if a user has fallen over and the radio is horizontal. The Lone Worker alarm is sent if the radio is inactive beyond a set time.
                </Text>
              </div>
            </Section>
          </Col>
          <Col md={8} lg={6} className="position-relative mb-5 mb-lg-0">
            <ImageHalfContainer>
              <div className="h-100">
                <img src={imgHalf} alt="" className="img-half img-fluid" />
              </div>
            </ImageHalfContainer>
          </Col>
        </Row>
      </Container>
    </div>
  </>
);

export default Content1;
