import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";

// @ts-expect-error false positive image import failure
import iconLayout from "../../assets/image/png/icon-layout.png";
// @ts-expect-error false positive image import failure
import iconLayers from "../../assets/image/png/icon-layers.png";
// @ts-expect-error false positive image import failure
import iconResponsive from "../../assets/image/png/icon-responsive.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb, faLineChart, faRefresh } from '@fortawesome/pro-light-svg-icons'

interface ThemeProps {
  theme: DefaultTheme;
}

const SectionStyled = styled(Section)<ThemeProps>`
  padding-top: 47px;
  padding-bottom: 23px;
  @media ${device.md} {
    padding-top: 87px;
    padding-bottom: 63px;
  }
`;

interface FeatureCardProps {
  icon?: any;
  title?: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({
  icon,
  title,
  children,
  ...rest
}) => (
  <Box {...rest}>
    <Box mb={[3, 3, 3, 4]} pb={2}>
      <FontAwesomeIcon size="3x" icon={icon} />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const Feature = () => (
  <>
    {/* <!-- Feature section --> */}
    <SectionStyled>
      <Container>
        <Row className="justify-content-center">
          <Col lg="4" className="mb-5 mb-lg-4">
            <FeatureCard title="Always Connected" icon={faRefresh}>
              Say goodbye to noisy tannoy announcements, multiple phone systems and clunky hardware. You can do all of this from one device.
            </FeatureCard>
          </Col>
          <Col lg="4" className="mb-5 mb-lg-4">
            <FeatureCard title="Supercharge Efficiency" icon={faLineChart}>
              Retail is more competitive than ever. Improve your margins and drive productivity by using valuable people efficiently.
            </FeatureCard>
          </Col>
          <Col lg="4" className="mb-5 mb-lg-4">
            <FeatureCard title="Informed workforce" icon={faLightbulb}>
              Allow store colleagues to use shared knowledge, and keep them updated via messages from HQ directly to their headsets.
            </FeatureCard>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default Feature;
