import React from "react";
import { Helmet } from "react-helmet"

import Hero from "../sections/internal-communication/Hero";
import Feature from "../sections/internal-communication/Feature";
import Content1 from "../sections/internal-communication/Content1";
import CTA from "../sections/internal-communication/CTA";
import PageWrapper from "../components/PageWrapper";
import Content2 from '../sections/internal-communication/Content2'
import CaseList2 from '../sections/internal-communication/CaseList2'
import Content3 from '../sections/internal-communication/Content3'

const LandingPage4 = () => {
  return (
    <>
      <PageWrapper headerDark>
        <Helmet>
          <title>Internal Communication</title>
          <meta name="description" content="A leading supplier of ShopWatch &amp; TownLink radio systems, ShopSafe offer effective and affordable crime reduction radio schemes throughout the UK." />
        </Helmet>
        <Hero />
        <Feature />
        <Content1 />
        <CaseList2 />
        <Content2 bg="#FFF" />
        <Content3 />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default LandingPage4;
