import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Section } from "../../components/Core";
import CaseCard from "../../components/CaseCard";

// @ts-expect-error false positive image import failure
import imgCase1 from "../../assets/image/case-studies/southend-small.jpg";
// @ts-expect-error false positive image import failure
import imgCase2 from "../../assets/image/case-studies/kingdom-small.jpg";
// @ts-expect-error false positive image import failure
import imgBrand1 from "../../assets/image/jpeg/case-card-brand-img.jpg";
// @ts-expect-error false positive image import failure
import imgBrand2 from "../../assets/image/jpeg/case-card-brand-img-2.jpg";

const CaseList2 = () => (
  <>
    {/* <!-- Feature section --> */}
    <Section bg="#F7F7FB" className="position-relative">
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col lg="6" className="mb-5 mb-lg-0">
            <CaseCard
              img={imgCase1}
              title="Southend-on-Sea"
              to="/southend-case-study"
            >
              The ‘Intelligence Hub’ will constitute a significant step forward - transforming services and offering potential for revenue generation and trading.
            </CaseCard>
          </Col>
          <Col lg="6" className="mb-5 mb-lg-0">
            <CaseCard
              img={imgCase2}
              title="Kingdom Shopping Centre"
              to="/kingdom-shopping-centre-case-study"
            >
              Kingdom Shopping Centre in Glenrothes, fife Gets a Two For One system - radio and an RFID powered tour guarding solution in one.
            </CaseCard>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default CaseList2;
