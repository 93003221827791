import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";

// @ts-expect-error false positive image import failure
import imgHalf from "../../assets/image/radios/vocovo.jpeg";

const ImageHalfContainer = styled(Box)`
  position: static;
  right: -260px;
  z-index: -1;
  height: 100%;
  overflow: hidden;

  .img-half {
    height: auto;
    @media ${device.lg} {
      height: 100%;
    }
  }
  @media ${device.lg} {
    position: absolute !important;
    height: 100%;
    right: -500px;
  }
  @media ${device.xl} {
    position: absolute !important;
    height: 100%;
    right: -500px;
  }
`;

interface ThemeProps {
  theme: DefaultTheme;
}

const Content1 = () => (
  <>
    <div className="overflow-hidden">
      <Container>
        <Row>
          <Col
            md={8}
            lg={5}
            data-aos="fade-right"
            data-aos-duration="750"
            data-aos-once="true"
            data-aos-delay="500"
          >
            <Section py={[5, null, null, "50px"]}>
              <div className="omga-02__content-text section-title order-lg-1">
                <Title>VoCoVo Headsets</Title>
                <Text fontSize="24px" fontWeight="700" color="primary">
                  Light and comfortable
                </Text>
                <Text variant="small" mb={3}>
                  28g with padded earpieces and options to suit every head, our
                  Headsets are designed for all-day (or all-night) wear.
                </Text>
                <Text fontSize="24px" fontWeight="700" color="primary">
                  100% real-time voice
                </Text>
                <Text variant="small" mb={3}>
                  Powered by full-duplex, every conversation is natural with
                  zero dropouts – improving efficiency from training to stock
                  checks.
                </Text>
                <Text fontSize="24px" fontWeight="700" color="primary">
                  48-hour battery life
                </Text>
                <Text variant="small" mb={3}>
                  Fully-charged in 3 hours, our Headsets last for up to 48 hours
                  on a single charge. That’s over twice as long as any
                  competitor.
                </Text>
                <Text fontSize="24px" fontWeight="700" color="primary">
                  Easy to scale
                </Text>
                <Text variant="small" mb={3}>
                  Add unlimited Headsets as your business grows, or to meet
                  demand during peak times. Just add Base Units and go!
                </Text>
              </div>
            </Section>
          </Col>
          <Col md={10} lg={6} className="position-relative mb-5 mb-lg-0">
            <ImageHalfContainer>
              <div className="h-100">
                <img src={imgHalf} alt="" className="img-half img-fluid" />
              </div>
            </ImageHalfContainer>
          </Col>
        </Row>
      </Container>
    </div>
  </>
);

export default Content1;
