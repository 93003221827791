import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";

import { Title, Button, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";
import data from "../../../site/content/index.json";

// @ts-expect-error false positive image import failure
import imgContentMobile from "../../assets/image/home/home-4.png";

const ShapeCard = styled(Box)`
  width: 305px;
  box-shadow: 0 32px 34px rgba(28, 27, 32, 0.2);
  position: absolute;
  top: 40%;
  left: 0%;
  transform: scale(0.85);
  @media ${device.sm} {
    left: 0;
    transform: scale(1);
  }
  @media ${device.md} {
    left: 0%;
  }
`;

const Content1 = ({ bg = "#f7f7fb" }: { bg?: string }) => (
  <>
    {/* <!-- Content section 1 --> */}
    <Section bg={bg}>
      <Container>
        <Row className="align-items-center">
          <Col md="12" lg="5" className="order-lg-1">
            <div className="position-relative pl-lg-5">
              <div className="pl-lg-4 text-center text-lg-right">
                <img src={data.content_3.image} alt="" className="img-fluid" />
              </div>

              <ShapeCard
                borderRadius={8}
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-once="true"
                data-aos-delay="500"
              >
                <Box
                  bg="warning"
                  p="18px"
                  borderRadius={8}
                  className="d-flex align-items-center"
                  opacity={0.9}
                >
                  <Box
                    width="30px"
                    minWidth="30px"
                    height="30px"
                    minHeight="30px"
                    bg="secondary"
                    color="light"
                    borderRadius="50%"
                    className="d-flex align-items-center justify-content-center"
                    mr={3}
                  >
                    <i className="fas fa-bullhorn"></i>
                  </Box>
                  <Title
                    textColor="light"
                    variant="card"
                    fontSize="18px"
                    lineHeight="18px"
                    fontWeight={300}
                    mb={0}
                  >
                    {data.content_3.cards[0].text}
                  </Title>
                </Box>
              </ShapeCard>
            </div>
          </Col>
          <Col lg="1" className="order-lg-2" />
          <Col lg="6" className="order-lg-3 mt-5 mt-lg-0">
            <Box>
              <Title fontSize="42px !important">Secure your store.</Title>
              <Text mb={4}>Our innovative ‘Smart Radio’ solutions combine the convenience and resilience of instant group communication with value-added features such as video recording, positioning and lone worker support.<br /><br />Enhanced by cellular our solutions can be deployed faster and go further.</Text>
            </Box>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Content1;
